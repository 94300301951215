<template>
    <modal-lateral ref="modalFiltroPasarela" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 103px)">
            <div class="row mx-0 justify-center">
                <div class="col-11 mt-3">
                    <p class="pl-3 text-general f-12">Fecha de pago</p>
                    <el-date-picker
					size="small"
                    v-model="fechas"
                    type="daterange"
                    range-separator="-"
                    class="w-100"
                    start-placeholder="Fecha Inicio"
                    end-placeholder="Fecha Fin"
                    />
                </div>
                <div class="col-11 mt-4">
                    <p class="pl-3 text-general f-12">Cedis</p>
                    <el-select v-model="idCedis" class="w-100" size="small">
                        <el-option
                        v-for="item in selectCedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="pl-3 text-general f-12">Medio de pago</p>
                    <el-select v-model="medioPago" class="w-100" size="small">
                        <el-option
                        v-for="(item,key) in mediosPagos"
                        :key="key"
                        :label="item"
                        :value="item"
                        />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <div class="row mx-0 mb-3">
                        <div class="col-12 bg-light-f5 cr-pointer d-middle border br-8 " style="height:32px;">
                            <span class="f-14 text-general">Origen del pago</span>
							<div class="f-14 wh-20 rounded-circle bg-dark ml-auto text-white d-middle-center">{{checkTipo.length}}</div>
                        </div>
                        <div class="col-12 mt-2 pl-3">
                            <el-checkbox-group v-model="checkTipo">
                                <el-checkbox :label="1" class="w-100 f-16 text-general check-dark mt-2">
                                    Pedido del Cliente
                                </el-checkbox>
                                <el-checkbox :label="2" class="w-100 f-16 text-general check-dark mt-2">
                                    Pedido del Vendedor
                                </el-checkbox>
                                <el-checkbox :label="3" class="w-100 f-16 text-general check-dark mt-2">
                                    Entrega de Dinero
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div class="col-11 mt-3">
                    <div class="row mx-0 mb-3">
                        <div class="col-12 bg-light-f5 cr-pointer d-middle border br-8 " style="height:32px;">
                            <span class="f-14 text-general">Estado</span>
							<div class="f-14 wh-20 rounded-circle bg-dark ml-auto text-white d-middle-center">{{checkEstado.length}}</div>
                        </div>
                        <div class="col-12 mt-2 pl-3">
                            <el-checkbox-group v-model="checkEstado">
                                <el-checkbox :label="4" class="w-100 f-16 text-general check-dark mt-2">
                                    Aprobado
                                </el-checkbox>
                                <el-checkbox :label="6" class="w-100 f-16 text-general check-dark mt-2">
                                    Rechazado
                                </el-checkbox>
                                <!-- <el-checkbox :label="1" class="w-100 f-16 text-general check-blue mt-2">
                                    Pendiente
                                </el-checkbox> -->
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="bg-white cr-pointer text-general border-black d-middle-center br-8" style="height:32px;">
                    Limpiar
                </div>
            </div>
            <div class="col">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center" style="height:32px;" @click="filtrar()">
                    Filtrar
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/pagos"
export default {
    props:{
        idCuenta:{
            type:[Number,String],
            default:0
        }
    },
    data(){
        return{
            fechas:[],
            checkTipo:[1,2,3],
            checkEstado:[4,6],
            idCedis:'',
            medioPago:'',
            mediosPagos:[]

        }
    },
    computed:{
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        }
    },
    methods: {
        toggle(){
            this.getMediosDepago()
            this.$refs.modalFiltroPasarela.toggle();
        },
        async getMediosDepago(){
            try {

                const {data} = await Pagos.getMediosDepago(this.idCuenta)
                this.mediosPagos = data.medios_pago


            } catch (e){
                this.error_catch(e)
            }
        },
        async filtrar(){

            let cliente = 0
            let lechero = 0
            let entrega = 0

            for (const t of this.checkTipo){
                if(t == 1) cliente = 1
                if(t == 2) lechero = 1
                if(t == 3) entrega = 1
            }

            const params = {
                fechas: this.fechas,
                cliente,
                lechero,
                entrega,
                checkEstado: this.checkEstado,
                idCedis: this.idCedis ?? 0,
                medioPago: this.medioPago
            }

            const {data} = await Pagos.filtroPagos(this.idCuenta, params)
            this.$emit('filtrar',data.pagos)
            this.$refs.modalFiltroPasarela.toggle();

        }
    }
}
</script>
